@font-face {
    font-family: righteous;
    src: url('./Righteous/Righteous-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: roboto;
    src: url('./Roboto/Roboto-Regular.ttf');
}

@font-face{
    font-family: pretendard;
    src: url('./Pretendard/PretendardVariable.woff2');
}